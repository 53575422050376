import React from 'react';
import { Card } from '@mui/material';
import PageContainer from 'src/components/common/PageContainer';
import Breadcrumb from 'src/layout/full/shared/breadcrumb/Breadcrumb';
import Toolbar from 'src/components/common/Toolbar';
import ReferEarnList from 'src/components/admin/refer&Earn/Refer&EarnList';

const RferEarn = () => {
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');

  return (
    <PageContainer title='Refer a Friend'>
      <Breadcrumb
        title='Refer a Friend'
      
      />
      <Card>
        <Toolbar
          onSearchProps={true}
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <ReferEarnList
          searchText={searchText}
          add={open}
          addClose={() => setOpen(false)}
        />
      </Card>
    </PageContainer>
  );
};

export default RferEarn;
