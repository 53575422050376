import { lazy } from 'react';
import Loadable from '../layout/full/shared/loadable/Loadable';
import RferEarn from 'src/views/admin/refer&earn';

const Users = Loadable(lazy(() => import('../views/admin/users')));
const Support = Loadable(lazy(() => import('../views/admin/supports/index')));
const Notifications = Loadable(
  lazy(() => import('../views/admin/notifications/index'))
);
const Earningmethods = Loadable(
  lazy(() => import('../views/admin/earningmethods/index'))
);
const RedeemPointsPage = Loadable(
  lazy(() => import('../views/admin/redeempoints/index'))
);
const EarningmethodsSort = Loadable(
  lazy(() => import('../views/admin/earningmethods/Sort'))
);
const SupportSort = Loadable(
  lazy(() => import('../views/admin/supports/Sort'))
);

const FAQ = Loadable(lazy(() => import('../views/admin/faq')));
const FaqSort = Loadable(lazy(() => import('src/views/admin/faq/Sort')));

const Customers = Loadable(
  lazy(() => import('../views/admin/customer-management'))
);
const Cars = Loadable(lazy(() => import('../views/admin/cars/index')));
const CarsSort = Loadable(lazy(() => import('../views/admin/cars/Sort')));
const Servies = Loadable(lazy(() => import('../views/admin/service/index')));
const ServiesSort = Loadable(lazy(() => import('../views/admin/service/Sort')));
const ServiesBooking = Loadable(
  lazy(() => import('../views/admin/servicebooking/index'))
);

const Accessories = Loadable(
  lazy(() => import('../views/admin/accessories/index'))
);
const AccessoriesSort = Loadable(
  lazy(() => import('../views/admin/accessories/Sort'))
);

const CustomerDetails = Loadable(
  lazy(() => import('src/views/admin/customer-management/CustomerDetails'))
);

const Reason = Loadable(
  lazy(() => import('src/views/admin/management/reason'))
);

const Banner = Loadable(lazy(() => import('../views/admin/management/banner')));
const BannerSort = Loadable(
  lazy(() => import('../views/admin/management/banner/Sort'))
);

const Product = Loadable(lazy(() => import('../views/admin/products')));
const ProductSort = Loadable(
  lazy(() => import('../views/admin/products/Sort'))
);
const AddProduct = Loadable(
  lazy(() => import('../views/admin/products/AddProduct'))
);
const EditProduct = Loadable(
  lazy(() => import('../views/admin/products/EditProduct'))
);
const ProductImage = Loadable(
  lazy(() => import('../views/admin/products/ProductImages'))
);
const ProductImageSort = Loadable(
  lazy(() => import('../views/admin/products/ProductImageSort'))
);
const ProductView = Loadable(
  lazy(() => import('../views/admin/products/ProductView'))
);

const LoginLogs = Loadable(
  lazy(() => import('src/views/admin/logs/LoginLogs'))
);

const AppRoutes = [
  {
    path: 'users',
    children: [
      {
        path: '',
        element: <Users />,
      },
    ],
  },

  {
    path: 'customer',
    children: [
      {
        path: '',
        element: <Customers />,
      },
      {
        path: ':CustomerID',
        element: <CustomerDetails />,
      },
    ],
  },
  {
    path: '/cars',
    children: [
      {
        path: '',
        element: <Cars />,
      },
      {
        path: 'sort',
        element: <CarsSort />,
      },
    ],
  },
  {
    path: '/services',
    children: [
      {
        path: '',
        element: <Servies />,
      },
      {
        path: 'sort',
        element: <ServiesSort />,
      },
    ],
  },
  {
    path: '/service-booking',
    children: [
      {
        path: '',
        element: <ServiesBooking />,
      },
      {
        path: 'sort',
        element: <ServiesSort />,
      },
    ],
  },
  {
    path: '/accessories',
    children: [
      {
        path: '',
        element: <Accessories />,
      },
      {
        path: 'sort',
        element: <AccessoriesSort />,
      },
    ],
  },
  {
    path: '/notifications',
    children: [
      {
        path: '',
        element: <Notifications />,
      },
    ],
  },

  {
    path: 'faq',
    children: [
      {
        path: '',
        element: <FAQ />,
      },
      {
        path: 'sort',
        element: <FaqSort />,
      },
    ],
  },

  {
    path: 'earningmethods',
    children: [
      {
        path: '',
        element: <Earningmethods />,
      },
      {
        path: 'sort',
        element: <EarningmethodsSort />,
      },
    ],
  },
  {
    path: 'redeempoints',
    children: [
      {
        path: '',
        element: <RedeemPointsPage />,
      },
      {
        path: 'sort',
        element: <EarningmethodsSort />,
      },
    ],
  },

  {
    path: 'refer-&-earn',
    children: [
      {
        path: '',
        element: <RferEarn />,
      },
     
    ],
  },
  {
    path: 'support',
    children: [
      {
        path: '',
        element: <Support />,
      },
      {
        path: 'sort',
        element: <SupportSort />,
      },
    ],
  },
  {
    path: 'logs',
    children: [
      {
        path: 'login',
        element: <LoginLogs />,
      },
    ],
  },
  {
    path: 'management',
    children: [
      {
        path: 'banner',
        children: [
          {
            path: '',
            element: <Banner />,
          },
          {
            path: 'sort',
            element: <BannerSort />,
          },
        ],
      },
      {
        path: 'reason',
        children: [
          {
            path: '',
            element: <Reason />,
          },
          {
            path: 'sort',
            element: <BannerSort />,
          },
        ],
      },

      {
        path: 'products',
        children: [
          {
            path: '',
            element: <Product />,
          },
          {
            path: 'add',
            element: <AddProduct />,
          },
          {
            path: 'edit/:ProductID',
            element: <EditProduct />,
          },
          {
            path: 'view/:ProductID',
            element: <ProductView />,
          },
          {
            path: 'image/:ProductID',
            children: [
              {
                path: '',

                element: <ProductImage />,
              },
              {
                path: 'sort',
                element: <ProductImageSort />,
              },
            ],
          },
          {
            path: 'sort',
            element: <ProductSort />,
          },
        ],
      },
    ],
  },
];

export default AppRoutes;
