import { baseFunc } from "./server-base";

export const UploadsAPI = baseFunc("uploads");
export const UsersAPI = baseFunc("users.php");
export const ReasonAPI = baseFunc("reasons.php");
export const LoginLogsAPI = baseFunc("loginlogs.php");
export const CustomersAPI = baseFunc('customers.php');
export const FAQAPI = baseFunc('faqs.php');
export const SupportsAPI = baseFunc('supports.php');
export const EarningmethodsAPI = baseFunc('earningmethods.php');
export const NotificationsAPI = baseFunc('notifications.php');
export const BannersAPI = baseFunc('banners.php');
export const CarsAPI = baseFunc('cars.php');
export const ServiesAPI = baseFunc('services.php');
export const ServiesBookingAPI = baseFunc('servicebooking.php');
export const AccessoriesAPI = baseFunc('accessories.php');

export const CategoryAPI = baseFunc("categories")
export const ProductAPI = baseFunc("products")
export const ProductImageAPI = baseFunc("productimages")
export const DashbordAPI = baseFunc("dashboard.php")
export const CustomerEarningPointsAPI = baseFunc("customerearningpoints.php")
export const ShareAPI  = baseFunc("share.php")



