import React, { useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";

import { ShareAPI } from "src/http/server-apis";
import DataTable from "src/components/table/DataTable";
import { IListProps } from "src/interface";

import { useQuery } from "react-query";
import Spinner from "src/views/spinner/Spinner";

import dayjs from "dayjs";

const ReferEarnList: React.FC<IListProps> = (props) => {
  const { searchText, data, tablePagination } = props;

  const [listData, setListData] = useState<any>([]);

  const { isLoading } = useQuery(
    ["get-all-share-list"],
    () => ShareAPI("get", {}),
    {
      refetchOnWindowFocus: false,
      enabled: !data,
      onSuccess(data) {
        if (data?.status === 200)
          setListData(Array.isArray(data?.data) ? data.data : []);
      },
    }
  );

  const columns = useMemo(
    () => [
      {
        header: "Customer Name",
        accessorKey: "CustomerName",
        size: 25,
        cell: (cell: any) => (
          <Typography fontSize={"small"} textAlign="center">
            {cell.getValue()
              ? cell.getValue()
              : `${cell.row.original?.CustomerDetails?.FirstName || ""} ${
                  cell.row.original?.CustomerDetails?.MiddleName || ""
                } ${
                  cell.row.original?.CustomerDetails?.LastName || ""
                }`.trim() || "-"}
          </Typography>
        ),
      },
      {
        header: "Refered To",
        accessorKey: "PersonName",
        size: 25,
        cell: (cell: any) => (
          <Typography fontSize={"small"} textAlign="center">
            {cell.getValue()}
          </Typography>
        ),
      },
      {
        header: "Interested In",
        accessorKey: "ServiceDetails",
        size: 10,
        cell: (cell: any) => (
          <Typography fontSize={"small"} textAlign="center">
            {cell.getValue()?.ServiceName || "-"}
          </Typography>
        ),
      },
      {
        header: "Mobile",
        accessorKey: "MobileNo",
        size: 25,
        cell: (cell: any) => (
          <Box>
            <Typography fontSize={"small"} textAlign="center">
              {cell.getValue()}
            </Typography>
          </Box>
        ),
      },

      {
        header: "Date",
        accessorKey: "DOC",
        size: 30,
        cell: (cell: any) => (
          <Typography fontSize={"small"} textAlign="center">
            {dayjs(cell.getValue()).format("MMM DD, YYYY")}
          </Typography>
        ),
      },
    ],
    []
  );

  if (isLoading) return <Spinner />;

  return (
    <>
      <DataTable
        loading={false}
        showNotFound={data ? data.length === 0 : listData.length === 0}
        data={listData}
        columns={columns}
        searchText={searchText}
        tablePagination={tablePagination || true}
      />
    </>
  );
};

export default ReferEarnList;
