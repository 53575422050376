import { Box } from "@mui/material";
import RowSearch from "./RowSearch";
import { ChangeEvent } from "react";

const Toolbar = (props: any) => {
  const { searchText, onSearchProps, setSearchText, component } = props;

  const handleTextChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = e.target.value;
    if (value?.startsWith(" ")) value = value.trim();
    setSearchText(value);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          paddingBottom: 1,
       
        }}
      >
        {onSearchProps ? (
          <Box width={300}>
            <RowSearch
              value={searchText}
              onChange={(e) => handleTextChange(e)}
              placeholder="Search"
              setSearchText={setSearchText}
            />
          </Box>
        ) : null}
        {component ? <Box> {component}</Box> : null}
      </Box>
    </>
  );
};

export default Toolbar;
