import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable, { MyLoadable } from "../layout/full/shared/loadable/Loadable";
import AppRoutes from "./AppRouter";

const BlankLayout = Loadable(lazy(() => import("../layout/blank/BlankLayout")));
const Dashboard = Loadable(lazy(() => import("../views/admin/dashboard/Dashboard")));
const ComingSoon = Loadable(lazy(() => import("../views/ComingSoon")));
const ForbiddenAcess = Loadable(lazy(() => import("../views/ForbiddenAcess")));

const FullLayout = MyLoadable(lazy(() => import("../layout/full/FullLayout")));
const Login = Loadable(lazy(() => import("../views/authentication/Login")));
const ForgotPassword = Loadable(
  lazy(() => import("../views/authentication/ForgotPassword"))
);
const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const AuthInfo = Loadable(
  lazy(() => import("../views/authentication/AuthInfo"))
);
const LoginFailed = Loadable(
  lazy(() => import("../views/authentication/LoginFailed"))
);
const AllRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { index: true, element: <Navigate to={"/dashboard"} /> },
      { path: "/dashboard", exact: true, element: <Dashboard /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
      { path: "/coming-soon", element: <ComingSoon /> },
      { path: "/forbidden", element: <ForbiddenAcess /> },
      ...AppRoutes
    ],
  },

  {
    path: "/",
    element: <BlankLayout />,
    children: [
      { path: "/auth/404", element: <Error /> },
      { path: "/auth/login", element: <Login /> },
      { path: "/auth/forgot-password", element: <ForgotPassword /> },
      { path: "/auth/login-failed", element: <LoginFailed /> },
      { path: "/auth/info", element: <AuthInfo /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default AllRoutes;
