import React from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  CircularProgress,
  SxProps,
  Theme,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

interface IAddProps {
  title?: string;
  onClick?: () => void;
  disabled?: boolean;
  component?: React.ReactNode;
  sx?: SxProps<Theme>;
}
interface ITodoCardProps {
  title: string;
  showBackButton?: boolean;
  addProps?: IAddProps;
  buttonProps?: IAddProps;

  exportProps?: {
    title?: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => void;
  };
  sortProps?: {
    title?: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => void;
  };
  onYearChange?: (val: string) => void;
  onMonthYearChange?: (val: string) => void;
  onQuarterChange?: (val: string) => void;
  onMonthChange?: (val: string) => void;
  onSelectPeriod?: (val: string) => void;
}
const Breadcrumb: React.FC<ITodoCardProps> = ({
  title,
  addProps,
  showBackButton,
  exportProps,
  sortProps,
  buttonProps,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{
        borderRadius: "8px",
        p: "10px 20px 10px 20px",

        margin: "20px 0px",
        position: "relative",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette?.gray?.light,
      }}
    >
      <Grid
        item
        md={6}
        sm={6}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            marginRight: "20px",
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item md={6} sm={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
          }}
        >
       
          {buttonProps?.title ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={buttonProps.onClick}
              disabled={buttonProps?.disabled}
            >
              {buttonProps.title}
            </Button>
          ) : null}
          {sortProps ? (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={
                sortProps.loading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : null
              }
              disabled={sortProps?.disabled || sortProps.loading}
              onClick={sortProps.onClick}
            >
              {sortProps.title || "Reorder"}
            </Button>
          ) : null}

{exportProps ? (
            <Button
              variant="contained"
              color="secondary"
              startIcon={
                exportProps.loading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : null
              }
              disabled={exportProps?.disabled || exportProps.loading}
              onClick={exportProps.onClick}
            >
              {exportProps.title || "Export"}
            </Button>
          ) : null}

          {addProps?.title ? (
            <Button
              variant="contained"
              sx={{
                padding: "6px 30px",
              }}
              color="primary"
              onClick={addProps.onClick}
              disabled={addProps?.disabled}
            >
              {addProps.title}
            </Button>
          ) : null}

          {showBackButton ? (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Breadcrumb;
