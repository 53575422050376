import React from "react";
import { Helmet } from "react-helmet";
import { IPageContainer } from "../../interface";

const PageContainer: React.FC<IPageContainer> = ({
  title,
  description,
  children,
  blank,
}) => {
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <Helmet>
        <title>{title} | Crown Honda  </title>
        <meta name="description" content={description} />
      </Helmet>
      {blank ? (
        children
      ) : (
        <div className={blank ? "app-container" : ""}>{children}</div>
      )}
    </div>
  );
};

export default PageContainer;
