import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialState = {
  UserInfo: {
    UserID: '0',
    Rights: [],
  },
  timestamp: null,
  isAuthenticated: false,
};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.UserInfo = action.payload;
      state.timestamp = action?.payload ? dayjs() : null;
      state.isAuthenticated = true;
    },

    resetAuthInfo: (state, action) => {
      state.UserID = null;
      state.UserInfo = {
        UserID: '0',
        Rights: [],
      };
      state.timestamp = null;
      state.isAuthenticated = false;
    },
  },
});

export const { setUserInfo, resetAuthInfo } = UserSlice.actions;

export default UserSlice.reducer;
